@import './src/variables';

@layer tailwind-base, bootstrap, primeng, tailwind-utilities;
//@import 'bootstrap/dist/css/bootstrap.css';
//@import 'primeng/resources/primeng.css' layer(primeng1);
//@import 'primeng/resources/themes/md-light-indigo/theme.css' layer(theme);

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@import 'bootstrap/dist/css/bootstrap.css' layer(bootstrap);

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

.bg-image {
  background-attachment: fixed;
  background-image: linear-gradient(to bottom, $color-vni 0, rgba(0, 145, 167, .1) 100%), url('assets/img/bg_vcap_filter.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 100vh;
  overflow: hidden;
}

@mixin transform-translate-x($value) {
  transform: translate3d($value, 0, 0);
}
